<template>
  <div>
    <v-dialog
      v-model="openObligation"
      @click:outside="$emit('update:openObligation', false)"
      max-width="75%"
    >
      <slot name="children"></slot>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'OpenObligationDialogBox',
  props: ['openObligation'],
  data() {
    return {
      dialog: false,
    }
  },
  watch: {
    async dialog(val) {
      if (!val) {
        this.$emit('update:openObligation', false)
      }
    },
    openObligation(val) {
      if (!val) {
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    }
  },
}
</script>