<template>
  <div>
    <v-card
      class="mx-auto"
      outlined
    >
      <ListSkeleton v-if="listSkeleton"/>
      <v-card-text class="text-h5 pa-0" v-else-if="getObligationDialogData">
        <v-card-text class="pa-0 text-end">
          <h2 :class="locale !== 'he' ? 'mt-2 mx-2 d-inline float-left' : 'mt-2 mx-2 d-inline float-right'">{{$t('customer.field.proformaInvoices')}} - {{id}}.{{customerName}}</h2>
          <v-icon
            class="mt-2 mx-2 primary--text"
            @click="$emit('update:openObligation', false)"
            >mdi-close</v-icon
          >
        </v-card-text>
        <v-data-table
          :dense="true"
          class="custom-table ma-2 row-pointer"
          :headers="headers"
          :items="getObligationDialogData"
          :items-per-page="limit"
          :page.sync="page"
          fixed-header
          item-key="randomKey"
          style="word-break: break-word"
          :options.sync="pagination"
          item-class="py-4"
          @page-count="pageCount = $event"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          :search="search"
          @dblclick:row="doubleClick"
          @pagination="foundRow"
        >
          <template v-slot:[`item.from`]="props">
            {{ profileById.date_format ? formatDateDDMMYYYY(props.item.from) : parseDateYYYYMMDD(props.item.from) }}
          </template>
          <template v-slot:[`item.amount`]="props">
            {{ numberWithCommas(props.item.amount) }}
          </template>
          <template v-slot:[`item.balance`]="props">
            {{ numberWithCommas(props.item.balance) }}
          </template>
          <template
            v-slot:top
          >
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="9">
                </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    :autofocus="type ? true : false"
                    v-model="search"
                    clearable
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{numberOfRows}}</span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';

export default {
  name: 'ProformaInvoicesRecordsTable',
  props: ['type', 'openObligation', 'customerName', 'id'],
  components: {
    ListSkeleton
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      pagination: {},
      search: "",
      numberOfRows: "",
      listSkeleton: false,
    }
  },
  computed: {
    params() {
      return {
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
      };
    },
    ...mapGetters({
      getObligationDialogData: 'customer/getObligationDialogData',
      accessRight: 'accessRight',
      user: 'user',
      profileById: 'profile/profileById',
      locale: 'locale'
    }),
    headers() {
      return [
        { text: this.$t("customer.documentNo"), value: "documentNo", width: "120px" },
        { text: this.$t("customer.customerName"), value: "customerName", width: "130px" },
        { text: this.$t("customer.customerOrderNo"), value: "customerOrderNo", width: "180px" },
        { text: this.$t("customer.from"), value: "from", width: "140px" },
        { text: this.$t("customer.amount"), value: "amount", width: "100px" },
        { text: this.$t("customer.balance"), value: "balance", width: "140px" },
        { text: this.$t("customer.agent"), value: "agent", width: "140px" }, 
      ]
    }
  },
  watch: {
    type: {
      handler() {
        this.search = '';
        this.page = 1;
        this.limit = 10;
      },
      deep: true
    },
    params: {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = this.params.page
          this.limit = this.params.itemsPerPage
      },
      deep: true
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = Number(this.$route.query.page) || 1
          this.search = this.$route.query.search || ""
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    const payloadData = {
      type: this.type,
      customer_id: this.$route.params.customer_id
    }
    this.$store.dispatch('customer/GetObligationDialogData', payloadData)
  },
  methods: {
    formatDateDDMMYYYY,
    parseDateYYYYMMDD,
    foundRow(pagination) {
      this.numberOfRows = pagination.itemsLength
    },
    doubleClick(event, {item}) {
      this.$router.push('/invoice/invoiceAction/' + item.documentNo);
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : 0;
    },
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>