<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="my-1">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('contact.contactsList') }}</span>
        <v-btn
          v-if="accessRight.includes('create')"
          class="float-end"
          outlined
          small
          color="primary"
          @click="dialog = true"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('contact.contact') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <ContactListTable :listSkeleton.sync="listSkeleton" :contactDialog.sync="dialog" :customerId="customerId" @deleteItem="deleteItem" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactListTable from './ContactListTable';

export default {
  props:['customerId'],
  name: "ContactStep",
  components: {
    ContactListTable
  },
  computed: {
    ...mapGetters([
      'accessRight'
    ]),
  },
  data() {
    return {
      dialog: false,
      listSkeleton: false,
    }
  },
  methods: {
    async deleteItem(item) {
      await this.$store.dispatch('contact/DeleteContactItemById', item);
      await this.$store.dispatch('contact/GetContact', {page: 1, limit: 10, where_and: 'record_type_number,record_type|'+this.$route.params.customer_id+',customers', order_by: 'name|asc'}, {root: true});
    }
  }
}
</script>